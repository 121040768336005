import React from "react";
import "./Contact.css";

const ApplyCourse = () => {
  return (
    <>
      <section className="apply-job">
        

        <div class="">
          <form className="form">
            <input
              aria-label="Name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Full Name"
              required
            />

            <input
              aria-label="Email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter Email"
              required
            />

            <input
              aria-label="Mobile Number"
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Mobile Number"
              required
            />

            <select name="areaOfInterest" id="areaOfInterest">
              <option value="fswd">Full Stack Web Development</option>
              <option value="aiml">AI & ML</option>
              <option value="java">Java Training</option>
              <option value="ccpp">C/C++</option>
              <option value="python">Python Training</option>
            </select>


            <button type="submit" aria-label="Create Account">
              Apply
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ApplyCourse;
