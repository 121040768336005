import React from "react";
import "./Portfolio.css";
import { BiLinkExternal } from "react-icons/bi";

const Portfolio = () => {
  return (
    <>

          <img src="assets/portfolio/portfolio.jpg" alt="" className="portfolio-top-img" />
      <main className="main container">
        {/* Top img section start */}
        {/* Top img section End */}

        <h1 className="heading3">Our Works</h1>
        <section className="portfolio-section">
        <div className="row">
          <div className="work">
            <img src="assets/portfolio/CraniumIT.jpg" alt="" />
            <div className="layer">
              <h2>CraniumIT</h2>
              <a href="https://www.craniumit.com/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
          <div className="work">
            <img src="assets/portfolio/EarthServe.jpg" alt="" />
            <div className="layer">
              <h2>EarthServe</h2>

              <a href="https://theearthreserve.com/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
          <div className="work">
            <img src="assets/portfolio/IMPEL.jpg" alt="" />
            <div className="layer">
              <h2>IMPEL</h2>

              <a href="https://www.impelceramic.com/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="work">
            <img src="assets/portfolio/PAX.jpg" alt="" />
            <div className="layer">
              <h2>PAX</h2>

              <a href="https://paxhotels.in/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
          <div className="work">
            <img src="assets/portfolio/STREET9.jpg" alt="" />
            <div className="layer">
              <h2>STREET9</h2>
              <a href="https://street9.com/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
          <div className="work">
            <img src="assets/portfolio/VRISA.jpg" alt="" />
            <div className="layer">
              <h2>VRISA</h2>

              <a href="https://www.vrisainnovation.com/" target="_icoess">
                <BiLinkExternal />
              </a>
            </div>
          </div>
        </div>
        </section>
      </main>
    </>
  );
};

export default Portfolio;
