import React from "react";
import { useState } from "react";
import './Accordion.css';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";

const AccordionComp = ({ ...props }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{boxShadow: "0 0 0.2rem black", margin: "0.2rem",borderRadius:'50%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className="accordion-summary"
          sx={{ '&:hover':{backgroundColor:'#00d9ff'},}}
        >
              <Typography
                variant="h4"
                sx={{ width: `${props.titleWidth}`, flexShrink: 0,marginLeft:'2rem' }}
                >
                <ul>
                  <li>
                    <h4>
                {props.title}
                    </h4>
            </li>
          </ul>
              </Typography>
          <Typography variant="h4" sx={{ color: "text.secondary" }}>
            {props.title2}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{borderTop: '1px solid #00d9ff'}}>
          <Typography className="job-description" sx={{ position: "relative" }}>
            <h1>{props.jdhead}</h1>
            {props.jdpara && <p>{props.jdpara}</p>}
            {props.jdli1 && (
              <ul>
                {props.jdli1 && <li>{props.jdli1}</li>}
                {props.jdli2 && <li>{props.jdli2}</li>}
                {props.jdli3 && <li>{props.jdli3}</li>}
                {props.jdli4 && <li>{props.jdli4}</li>}
                {props.jdli5 && <li>{props.jdli5}</li>}
                {props.jdli6 && <li>{props.jdli6}</li>}
                {props.jdli7 && <li>{props.jdli7}</li>}
                {props.jdli8 && <li>{props.jdli8}</li>}
                {props.jdli9 && <li>{props.jdli9}</li>}
                {props.jdli10 && <li>{props.jdli10}</li>}
                {props.jdli11 && <li>{props.jdli11}</li>}
                {props.jdli12 && <li>{props.jdli12}</li>}
                {props.jdli13 && <li>{props.jdli13}</li>}
                {props.jdli14 && <li>{props.jdli14}</li>}
                {props.jdli15 && <li>{props.jdli15}</li>}
                {props.jdli16 && <li>{props.jdli16}</li>}
                {props.jdli17 && <li>{props.jdli17}</li>}
                {props.jdli18 && <li>{props.jdli18}</li>}
                {props.jdli19 && <li>{props.jdli19}</li>}
                {props.jdli20 && <li>{props.jdli20}</li>}
              </ul>
            )}
            {props.jdhead2 && <h1>{props.jdhead2}</h1>}
            {props.jd2li1 && (
              <ul>
                {props.jd2li1 && <li>{props.jd2li1}</li>}
                {props.jd2li2 && <li>{props.jd2li2}</li>}
                {props.jd2li3 && <li>{props.jd2li3}</li>}
                {props.jd2li4 && <li>{props.jd2li4}</li>}
                {props.jd2li5 && <li>{props.jd2li5}</li>}
                {props.jd2li6 && <li>{props.jd2li6}</li>}
                {props.jd2li7 && <li>{props.jd2li7}</li>}
                {props.jd2li8 && <li>{props.jd2li8}</li>}
                {props.jd2li9 && <li>{props.jd2li9}</li>}
                {props.jd2li10 && <li>{props.jd2li10}</li>}
              </ul>
            )}
            {props.jdhead3 && <h1>{props.jdhead3}</h1>}
            {props.jd3li1 && (
              <ul>
                {props.jd3li1 && <li>{props.jd3li1}</li>}
                {props.jd3li2 && <li>{props.jd3li2}</li>}
                {props.jd3li3 && <li>{props.jd3li3}</li>}
                {props.jd3li4 && <li>{props.jd3li4}</li>}
                {props.jd3li5 && <li>{props.jd3li5}</li>}
                {props.jd3li6 && <li>{props.jd3li6}</li>}
                {props.jd3li7 && <li>{props.jd3li7}</li>}
                {props.jd3li8 && <li>{props.jd3li8}</li>}
                {props.jd3li9 && <li>{props.jd3li9}</li>}
                {props.jd3li10 && <li>{props.jd3li10}</li>}
              </ul>
            )}

            {props.btn && (
              <a href="#apply">
                <button className="cta">Apply</button>
              </a>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionComp;
