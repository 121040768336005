import React from 'react';
import './Contact.css'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Contact = () => {
  return (
    <>
    
    {/* <section
          id="home-contact-section"
          className="home-contact-section container"
        >
          <div className="home-services-header">
            <h1 className="section-title">
              Contact <span>Us</span>
            </h1>
          </div>
          <div className="contact-container">
            <div className="contact-card">
              <div className="contact-left">
                <img src="assets/contact.jpg" alt="" />
                <p>
                  Talk to us for your business needs, all staffing requirements
                  and anything on technology. We would love to here from you &
                  share our expertise with you
                </p>
              </div>
              <div className="contact-right">
                <div class="contact-form">
                  <h3>Contact us</h3>
                  
                  <form>
                    <p>
                      <label>First Name</label>
                      <input type="text" name="firstName" />
                    </p>
                    <p>
                      <label>Last Name</label>
                      <input type="text" name="lastName" />
                    </p>
                    <p>
                      <label>Phone Number</label>
                      <input type="number" name="phone" />
                    </p>
                    <p>
                      <label>Email Adress</label>
                      <input type="email" name="email" />
                    </p>
                    <p className="block">
                      <label>Message</label>
                      <textarea name="message" rows="5"></textarea>
                    </p>
                    <p>
                      <button>Send</button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> */}

<section class="contact">
		<div class="content">
			<h2>Contact Us</h2>
			<p>Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you.</p>
		</div>
		<div class="contact-page-container">
			<div class="contactInfo">
				<div class="box">
					<div class="icon"><FaLocationDot /></div>
					<div class="text">
						<h3>Address</h3>
						<p>H.N.249, Peptech Town<br />Chhatarpur(M.P.), India<br/>471001</p>
					</div>
				</div>
				<div class="box">
					<div class="icon"><FaPhoneAlt /></div>
					<div class="text">
						<h3>Phone</h3>
						<p>+91 9981810146</p>
					</div>
				</div>
				<div class="box">
					<div class="icon"><IoMdMail /></div>
					<div class="text">
						<h3>Email</h3>
						<p>info@icoesolution.com</p>
					</div>
				</div>
				<h2 class="txt">Connect with us</h2>
				<ul class="sci">
					<li><a href="#"><ion-icon name="logo-facebook"></ion-icon></a></li>
					<li><a href="#"><ion-icon name="logo-twitter"></ion-icon></a></li>
					<li><a href="#"><ion-icon name="logo-linkedin"></ion-icon></a></li>
					<li><a href="#"><ion-icon name="logo-instagram"></ion-icon></a></li>
				</ul>
			</div>

			<div class="contactForm">
				<form>
					<h2>Send Message</h2>
					<div class="inputBox">
						<input type="text" name="" required="required"/>
						<span>Full Name</span>
					</div>
					<div class="inputBox">
						<input type="number" name="" required="required"/>
						<span>Phone</span>
					</div>
					<div class="inputBox">
						<input type="email" name="" required="required"/>
						<span>Email</span>
					</div>
					<div class="inputBox">
						<textarea  required="required"></textarea>
						<span>Type your Message...</span>
					</div>
					<div class="inputBox">
						<input type="submit" value="Send"/>
					</div>
				</form>
			</div>
		</div>
	</section>
    </>
  )
}

export default Contact;