import React from "react";
import TopSection from "../../../components/cards/top/TopSection";
import TextComponent from "../../../components/TextComponent/TextComponent";
import "./SwDevOutsourcing.css";
import ContactSection from "../../../components/contact/ContactSection";
import IconTextCard from "../../../components/cards/IconTextCard";
import { BiSolidCustomize } from "react-icons/bi";
import TechStackCard from "../../../components/cards/TechStackCard";
import { NavLink } from "react-router-dom";

const SwDevOutsourcing = () => {
  return (
    <>
      <div className="software-out-top">
      <TopSection
        heading={"Software Development & Outsourcing"}
        text={
          "We are software development company offereing offshore software development for large and small companies"
        }
      />
      </div>

      <main className="container">
        {/* Start Why ICOESS Section  */}
        <h1 className="heading3">Why ICOESS</h1>
        <TextComponent
          para1={
            "When you outsource your software development to ICOESS you get peace of mind by entrusting us your all or part of development responsibilities. We stay on schedule, ensure product quality and scale the teams as needed. You get full flexibility & control over the project just like your in-house team."
          }
          para2={
            "Leverage our end-to-end Application Development, Maintenance and Solutions that deliver measurable business value. User friendly and Scalable WebSite & Mobile App Development."
          }
          para3={
            "Our business domain knowledge, technology expertise & proven methodologies yield high quality solutions that add value to businesses make us one of India’s best software outsourcing companies."
          }
          para4={
            "From startups to enterprises, product companies to digital agencies, SMEs to governments, we have provided best-in-class software development services to them all."
          }
        />
        {/* End Why ICOESS Section  */}

        {/* Start What we offer section*/}
        <h1 className="heading1">Software Outsourcing Services</h1>
        <section id="what-we-offer" className="what-we-offer section container">
          <div className="row">
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Custom Software Development"}
              text={
                "Attractive Websites are doors of your Business. Our Team of Experts provides the custom build, Sophisticated & Full Featured Websites. Our Full Stack Developer team builds Robust Web Applications. So, if you are looking for E-Com web Sites, Shopping portals, Web Applications? Just knock the door of us, Tell your requirements and rest leave to us."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Web design & Development"}
              text={
                "Our company offers outsourced web design and development services. We have a dedicated team of experts who can handle your web projects efficiently. By outsourcing your web design and development needs to us, you can save time and resources while benefiting from our expertise.You can streamline your web projects and ensure a strong online presence."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Mobile app Development"}
              text={
                "Our company specializes in outsourced mobile app development services. We have a skilled team of experts ready to take on your mobile app projects efficiently and cost-effectively. We prioritize user-friendly design and feature-rich functionality, ensuring your mobile app aligns with your objectives.Reduce overhead, and bring your mobile app ideas to life."
              }
            />
          </div>
          <div className="row">
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Robotic Process Automation"}
              text={
                "Our company offers outsourced Robotic Process Automation (RPA) services. We have a dedicated team of RPA experts ready to streamline your business processes, increase efficiency, and reduce operational costs. By outsourcing your RPA needs to us, you can optimize your business operations and stay ahead in the competitive market."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Desktop development"}
              text={
                "Our company specializes in providing comprehensive desktop development services, catering to a diverse range of client needs. With a focus on innovation and user-centric design, we deliver customized desktop applications that are tailored to enhance productivity and efficiency. We are creating powerful and reliable solutions of modern desktop computing."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Software Testing"}
              text={
                "Our company specializes in outsourced software testing services. We have a highly skilled team of quality assurance experts ready to thoroughly test and validate your software applications. With our outsourced software testing services, you can confidently launch bug-free, high-quality software products and focus on your core business objectives."
              }
            />
            
          </div>
        </section>
        {/* End What we offer section*/}

        {/* Start Technology Stack Section */}
        <h3 className="heading3">Technology Stack</h3>
        <section className="technology-stack section container">
          <div className="row">
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"Frontend"}
              li1={"React"}
              li2={"HTML"}
              li3={"CSS"}
              li4={"Javascript"}
            />
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"Backend"}
              li1={"NodeJS"}
              li2={"JAVA"}
              li3={"PHP"}
            />
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"DataBases"}
              li1={"MongoDB"}
              li2={"MySQL"}
              li3={"PostGreSQL"}
              li4={"Oracle"}
            />
          </div>
          <div className="row">
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"Mobile Dev"}
              li1={"IOS"}
              li2={"Android"}
              li3={"React Native"}
              li4={"Flutter"}
            />
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"Desktop Dev"}
              li1={"C++"}
              li2={"Java"}
              li3={"C#"}
              li4={"Python"}
            />
            <TechStackCard
              icon={<BiSolidCustomize />}
              heading={"Testing"}
              li1={"Selenium"}
              li2={"Katalon Studio"}
              li3={"TestRail"}
            />
          </div>
        </section>
        {/* End Technology Stack Section */}
        {/*  */}
        <h1 className="heading1">Software Outsourcing Process</h1>
        <section className="section container">
          <div className="it-staffing-services">
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Delivering the Right Solution for Software Outsourcing</h1>
                <p>
                  Software outsourcing is a strategic approach that many
                  organizations leverage to access specialized skills, reduce
                  costs, and accelerate product development. However, ensuring
                  the success of software outsourcing projects requires careful
                  consideration and proactive management. To deliver the right
                  solution for software outsourcing, organizations should follow
                  a structured approach
                </p>
                <NavLink className="cta" to={"/contact"}>
                  SCHEDULE A CALL
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/swout/s1.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Advantages of Software Development Outsourcing</h1>
                <p>
                  Outsourcing software development offers several advantages,
                  including cost savings through lower labor expenses. It grants
                  access to a global talent pool with specialized skills,
                  expedites project timelines for faster product launches, and
                  allows organizations to focus on core business functions.
                  Scalability and risk-sharing are additional benefits, along
                  with diverse global perspectives and continuous development
                  across time zones. Predictable costs simplify budgeting, and
                  outsourcing frees in-house teams to innovate while staying
                  current with technology trends. Lastly, it reduces
                  administrative burdens, promoting efficiency in operations.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  SCHEDULE A CALL
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/swout/s2.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>ICOESS Solutions - Software Development Outsourcing</h1>
                <p>
                  ICOESS Solutions, a prominent IT company, offers robust
                  software development outsourcing services. With a track record
                  of excellence, ICOESS is a trusted partner for businesses
                  seeking to leverage the advantages of outsourcing their
                  software development needs.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  SCHEDULE A CALL
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/swout/s3.jpg" alt="img" />{" "}
              </div>
            </div>
          </div>
        </section>
        {/*  */}
      </main>

      {/* Start ContactSection */}
      <ContactSection
        text={
          "ICOESS aimes to support the functioning of many schools & Colleges in the vicinity of it’s facilities or at our partners premises across the country."
        }
      />
      {/* End ContactSection */}
    </>
  );
};

export default SwDevOutsourcing;
