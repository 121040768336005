import React from "react";

const TestimonialCard = () => {
  return (
    <>
      <div class="div4 eachdiv">
        <div class="userdetails">
          <div class="imgbox">
            <img
              src="assets/training/mern/sir.jpeg"
              alt=""
            />
          </div>
          <div class="detbox">
            <p class="name">Girjesh Mishra</p>
            <p class="designation">Director of ICOESS Solutions</p>
          </div>
        </div>
        <div class="review">
          <h4>An overall wonderful and rewarding experience</h4>
          <p>
            “ Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
            officia culpa repudiandae fugit temporibus quidem incidunt repellat
            vel molestiae placeat sequi, asperiores eius, voluptatem quo odit
            modi, eligendi recusandae sapiente? ”
          </p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
