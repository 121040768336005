import React from "react";
import "./Contact.css";
import { Link } from "react-router-dom";

const ApplyJob = () => {
  return (
    <>
      <section className="apply-job">
        <div className="home-services-header">
          <h1 className="section-title">
            <span>Apply</span> Here
          </h1>
        </div>

        <div class="">
          <form className="form">
            <input
              aria-label="Name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Full Name"
              aria-required="true"
            />

            <input
              aria-label="Email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter Email"
              aria-required="true"
            />

            <input
              aria-label="Mobile Number"
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Mobile Number"
              aria-required="true"
            />

            <select name="areaOfInterest" id="areaOfInterest">
              <option value="IT">IT</option>
              <option value="Finance">Finance</option>
              <option value="HR">HR</option>
              <option value="Trainer">Trainer</option>
              <option value="Sales/Marketing">Sales/Marketing</option>
            </select>

            <select name="areaOfInterest" id="areaOfInterest">
              <option className="option" value="Web Designer">
                Web Designer
              </option>
              <option className="option" value="Web Developer">
                Web Developer
              </option>
              <option className="option" value="Business Development Executive">
                Business Development Executive
              </option>
            </select>

            <input
              aria-label="Experience"
              type="number"
              name="experience"
              className="form-control"
              placeholder="Experience in Years"
              aria-required="true"
            />

            <textarea
              aria-label="Message"
              name="message"
              className="form-control mt-2"
              placeholder="Paste Your Resume"
              rows={10}
              aria-required="true"
            ></textarea>

            <button type="submit" aria-label="Create Account">
              Apply
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ApplyJob;
