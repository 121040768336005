/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import { Card } from "react-bootstrap";
import TestimonialCard from "../../../../components/cards/TestimonialCard";

const Cloud = () => {
  return (
    <>
      <section id="course-top" className="mern-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>AWS Cloud Architect</h1>
            <h3>
              AWS Cloud Architect course typically covers a comprehensive set of
              topics to equip participants with the skills and knowledge needed
              to design and implement scalable, secure, and high-performance
              solutions on the AWS platform.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src1={"assets/training/career.jpg"}
              title1={"Career Growth"}
              text1={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src2={"assets/training/payment.jpg"}
              title2={"Highest paid"}
              text2={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src3={"assets/training/productivity.jpg"}
              title3={"Productivity"}
              text3={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="top-mid">
        <div className="top-mid jcse">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>15 Dec, 2023</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>5 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <h1>Fees</h1>
            <p>&#8377;6999</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                The AWS Cloud Architect Training is a comprehensive program
                designed to empower participants with the skills and knowledge
                required to excel in architecting, deploying, and managing
                robust cloud solutions on the Amazon Web Services (AWS)
                platform. This extensive course covers a broad range of topics,
                from foundational AWS services to advanced architectural
                concepts, ensuring that participants gain both theoretical
                understanding and practical hands-on experience.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Comprehensive Curriculum
                </li>
                <li>
                  <VscPass />
                  Hands-on Labs and Projects
                </li>
                <li>
                  <VscPass />
                  Instructor-led Training
                </li>
                <li>
                  <VscPass />
                  Real-world Case Studies
                </li>
                <li>
                  <VscPass />
                  Certification by Icoess Solutions
                </li>
                <li>
                  <VscPass />
                  24*7 Support
                </li>
                <li>
                  <VscPass />
                  Practical Application of AWS Services
                </li>
                <li>
                  <VscPass />
                  Cost Optimization Strategies
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">
                AWS Cloud Architect Certification Advantage
              </h5>
              <p>
              Earning an AWS Certified Solutions Architect - Professional certification offers numerous advantages for professionals in the cloud computing field, particularly those specializing in AWS. Here are some key benefits:
              </p>
              <ul>
                <li>
                  <b> Validation of Expertise:</b>The certification validates
                  your expertise in designing and deploying scalable, highly
                  available, and fault-tolerant systems on AWS.
                </li>
                <li>
                  <b>Recognition in the Industry:</b>AWS certifications are
                  widely recognized in the IT industry, and the Solutions
                  Architect - Professional certification is particularly valued
                  for demonstrating advanced skills.
                </li>
                <li>
                  <b>Career Advancement:</b>Enhances your professional profile
                  and opens doors to new career opportunities and advancements,
                  especially in roles related to cloud architecture and design.
                </li>
                <li>
                  <b>Increased Employability:</b>Employers often prioritize
                  candidates with AWS certifications, and holding the AWS
                  Certified Solutions Architect - Professional credential can
                  make you a more attractive candidate.
                </li>
                <li>
                  <b>Expertise in AWS Services</b>Demonstrates your in-depth
                  knowledge of AWS services, architecture best practices, and
                  the ability to design robust and scalable solutions.
                </li>
                <li>
                  <b>Alignment with Industry Standards:</b>The certification
                  aligns with industry-recognized best practices, ensuring that
                  certified professionals adhere to high standards in cloud
                  architecture.
                </li>
                <li>
                  <b>Confidence of Employers and Clients:</b>Employers and
                  clients have confidence in the skills and expertise of AWS
                  Certified Solutions Architects, making them valuable assets
                  for project teams.
                </li>
                <li>
                  <b>Continuous Learning and Updates:</b>AWS certifications
                  require ongoing learning to keep skills current. This
                  commitment to continuous improvement is valuable in the
                  fast-paced world of technology.
                </li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.jpg"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
              <h3 className="heading3">Core</h3>
              <AccordionComp
                title={"AWS Technical Essentials"}
                titleWidth={"100%"}
                jdhead={"Course Overview"}
                jdli1={"Introduction to Cloud Computing"}
                jdli2={"Introduction to AWS"}
                jdli3={"Storage and Content Delivery"}
                jdli4={
                  "Compute Services and Networking"
                }
                jdli5={"AWS Managed Services and Databases"}
                jdli6={"Deployment and Management"}
                jdli7={"Practice Project"}
              />
              <AccordionComp
                title={"AWS Solutions Architect"}
                titleWidth={"100%"}
                jdli1={"Course Overview"}
                jdli2={"AWS Overview"}
                jdli3={"Designing Highly Available, Cost-efficient, Fault-tolerant, and Scalable Systems"}
                jdli4={"Identity Access Management (IAM)"}
                jdli5={"Amazon Virtual Private Cloud (VPC)"}
                jdli6={"Elastic Compute Cloud (EC2)"}
                jdli7={"Amazon Simple Storage Service (S3)"}
                jdli8={"Amazon Route 53"}
                jdli9={"Databases"}
                jdli10={"Application Services"}
                jdli11={"Security Practices for optimum Cloud Deployment"}
                jdli12={"Disaster Recovery"}
                jdli13={"Troubleshooting"}
                jdli14={"AWS Solutions Architect Exam"}
                
              />
              <h3 className="heading3">Elective</h3>
              <AccordionComp
                title={"AWS Developer Associate"}
                titleWidth={"100%"}
                jdli1={
                  "AWS Overview"
                }
                jdli2={
                  "AWS Services"
                }
              />
              <AccordionComp
                title={"AWS Database Migration"}
                titleWidth={"100%"}
                jdli1={"Overview"}
                jdli2={"Overview of AWS DMS"}
                jdli3={"Schema Conversion Tool"}
                jdli4={"Database Migration Service"}
                jdli5={
                  "AWS Database Migration Service Review"
                }
                
              />
              <AccordionComp
                title={"AWS SysOps Associate"}
                titleWidth={"100%"}
              
                jdli1={" Course Introduction"}
                jdli2={"Monitoring and Metrics"}
                jdli3={"Networking"}
                jdli4={"Analysis and Cost Effectiveness in AWS"}
                jdli5={"Security"}
                jdli6={"Data Management"}
                jdli7={"High Availability"}
                jdli8={"Deployment and Provisioning"}
                jdli9={"Best Practices for AWS SysOps Administrator Associate"}
                jdli10={"Practice Projects"}
              />
              
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 5 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}

        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap center">
            <img src="assets/training/cloud/appservice.avif" alt="" />
            <img src="assets/training/cloud/aws-iam.avif" alt="" />
            <img src="assets/training/cloud/aws-kms.avif" alt="" />
            <img src="assets/training/cloud/aws-lambda.avif" alt="" />
            <img src="assets/training/cloud/Virtual-Machines.avif" alt="" />
            <img src="assets/training/cloud/amazon-ebs.avif" alt="" />
            <img src="assets/training/cloud/amazon-efs.avif" alt="" />
            <img src="assets/training/cloud/amazon-elb.avif" alt="" />
            <img src="assets/training/cloud/amazon-route-53.avif" alt="" />
            <img src="assets/training/cloud/amazon-S3.avif" alt="" />
          </div>
        </section>
        {/* End Technology we use Section  */}
        <section className="testimonial-section section ">
          <h2 className="heading2">Hear From Our Hiring Partners</h2>
          <div className="row">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </section>
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default Cloud;
