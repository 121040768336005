import React, { useState } from "react";
import "./HoverComponentView.css";
import HoverCard from "../cards/HoverCard";

const HoverComponentView = () => {
  const [isDefault, setIsDefault] = useState(true);
  const [isHealthCare, setIsHealthCare] = useState(false);
  const [isEcomm, setIsEcomm] = useState(false);
  const [isEducation, setIsEducation] = useState(false);
  const [isRealEstate, setIsRealEstate] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);
  const [isTourism, setIsTourism] = useState(false);

  // Health Care Function Starts
  function healthCareEnter() {
    setIsHealthCare(true);
    setIsDefault(false);
  }
  function healthCareLeave() {
    setIsHealthCare(false);
    setIsDefault(true);
  }
  // Health Care Function Ends

  // Ecomm Function Starts
  function EcommEnter() {
    setIsEcomm(true);
    setIsDefault(false);
  }
  function EcommLeave() {
    setIsEcomm(false);
    setIsDefault(true);
  }
  // Ecomm Function Ends

  // Education Function Starts
  function EducationEnter() {
    setIsEducation(true);
    setIsDefault(false);
  }
  function EducationLeave() {
    setIsEducation(false);
    setIsDefault(true);
  }
  // Education Function Ends

  // Real Estate Function Starts
  function RealEstateEnter() {
    setIsRealEstate(true);
    setIsDefault(false);
  }
  function RealEstateLeave() {
    setIsRealEstate(false);
    setIsDefault(true);
  }
  // Real Estate Function Ends

  // Real Media Function Starts
  function MediaEnter() {
    setIsMedia(true);
    setIsDefault(false);
  }
  function MediaLeave() {
    setIsMedia(false);
    setIsDefault(true);
  }
  // Real Media Function Ends

  // Real Logistics Function Starts
  function LogisticsEnter() {
    setIsLogistics(true);
    setIsDefault(false);
  }
  function LogisticsLeave() {
    setIsLogistics(false);
    setIsDefault(true);
  }
  // Real Logistics Function Ends

  // Real Tourism Function Starts
  function TourismEnter() {
    setIsTourism(true);
    setIsDefault(false);
  }
  function TourismLeave() {
    setIsTourism(false);
    setIsDefault(true);
  }
  // Real Tourism Function Ends

  return (
    <>
      <div className="hoverComponent-container">
        <ul className="hoverComponent-left">
          <li onMouseEnter={healthCareEnter} onMouseLeave={healthCareLeave}>
            Health Care
          </li>
          <li onMouseEnter={EcommEnter} onMouseLeave={EcommLeave}>
            Ecomm Solutions
          </li>
          <li onMouseEnter={EducationEnter} onMouseLeave={EducationLeave}>
            Education
          </li>
          <li onMouseEnter={RealEstateEnter} onMouseLeave={RealEstateLeave}>
            Real Estate
          </li>
          <li onMouseEnter={MediaEnter} onMouseLeave={MediaLeave}>
            Media & Entertainment
          </li>
          <li onMouseEnter={LogisticsEnter} onMouseLeave={LogisticsLeave}>
            Logistics
          </li>
          <li onMouseEnter={TourismEnter} onMouseLeave={TourismLeave}>
            Tourism
          </li>
        </ul>

        <div className="hoverComponent-right">
          {isDefault && (
            <HoverCard
              heading={"Industries we serve"}
              text={`Hover on buttons to see details`}
              bgClass={'default-bg'}
            />
            // <Card3
            // src={'assets/training/fullstack.png'}
            //   title={"Default"}
            //   text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
            // placeat.`}
            // />
          )}
          {isHealthCare && (
            <HoverCard
              heading={"Health Care"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'health-bg'}
            />
          )}
          {isEcomm && (
            <HoverCard
              heading={"Ecomm Solutions"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'ecom-bg'}
            />
          )}
          {isEducation && (
            <HoverCard
              heading={"Education"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
      placeat.`}
      bgClass={'edu-bg'}
            />
          )}
          {isRealEstate && (
            <HoverCard
              heading={"Real Estate"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'realestate-bg'}

            />
          )}
          {isMedia && (
            <HoverCard
              heading={"Media & Entertainment"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'media-bg'}

            />
          )}
          {isLogistics && (
            <HoverCard
              heading={"Logistics"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'logistics-bg'}
            />
          )}
          {isTourism && (
            <HoverCard
              heading={"Tourism"}
              text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'tourism-bg'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HoverComponentView;
