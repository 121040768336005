import React from "react";

const TechCard = ({heading,icon,alt,text}) => {
  return (
    <>
      <div className="tech-card-container">
        {icon}
        <h1>{heading}</h1>
      </div>
    </>
  );
};

export default TechCard;
