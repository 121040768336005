import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import CourseCard from "../cards/CourseCard";

const CourseSlider = ({...props }) => {
  // if(window.innerWidth<='1200' && window.innerWidth>='800'){
  //   setSlides(2)
  // }
  // else if(window.innerWidth<='800'){
  //   setSlides(1)
  // }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        }
      }
    ],
  };

  return (
    <>
      <Slider {...settings} className="card-slider">
        <div className="slider-item">
          <CourseCard
            src={props.src1}
            title={props.title1}
            text={props.text1}
          />
        </div>
        <div className="slider-item">
        <CourseCard
            src={props.src2}
            title={props.title2}
            text={props.text2}
          />
        </div>
        <div className="slider-item">
        <CourseCard
            src={props.src3}
            title={props.title3}
            text={props.text3}
          />
        </div>
        
      </Slider>
    </>
  );
};

export default CourseSlider;
