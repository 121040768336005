import React from 'react'
import { NavLink } from "react-router-dom";
import './Cards2.css'

const HoverCard = ({bgClass,heading,text}) => {
  return (
    <>
    
        <div className={`hover-card ${bgClass}`}>
          <h1>{heading}</h1>
          <p>{text}</p>
          <NavLink className="cta" to={"/contact"}>
            Connect with us{" "}
          </NavLink>
        </div>
    </>
  )
}

export default HoverCard