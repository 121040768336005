import React from "react";
import "./ITStaffing.css";
import { NavLink } from "react-router-dom";
import ContactSection from "../../../components/contact/ContactSection";
import TopSection from "../../../components/cards/top/TopSection";
import TextComponent from "../../../components/TextComponent/TextComponent";

const ITStaffing = () => {
  return (
    <>
      <div className="itstaff-top">
        <TopSection
          heading={"IT Staffing Services"}
          text={"Solutions to all your IT Staffing challanges"}
          to={"/contact"}
        />
      </div>

      <main className="container">
        {/* Start IT Talent Section  */}

        <h1 className="heading1">IT talent that drives results</h1>

        <TextComponent
          para1={"EMPOWERING BUSINESS GROWTH WITH ON-DEMAND TECH TALENT"}
          para2={
            "Business moves fast. Digital transformation makes you even faster.Is your IT department keeping up with demand?"
          }
          para3={
            "At Icoess Solutions, we accelerate growth for our customers by equipping them with the professional IT workforce they need right now. We offer a customizable, scalable approach to IT staffing, supporting where you are today and where you need to be in the future."
          }
          para4={
            "Get resumes in hand within 24-48 hours and start running a more flexible and productive organization."
          }
        />
        {/* End IT Talent Section  */}

        {/* Start Technology we use Section  */}
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card2-item article">
              <div className="card2-info">
                <h1>IT Staff Augmentation</h1>
                <h4 className="heading4">INSTANT ACCESS, WITH ROOM TO GROW</h4>
                <p>
                  Exceptional IT talent and scalable staffing solutions for
                  companies of all sizes. Our highly skilled IT consultants
                  extend your team’s capabilities without the overhead of
                  in-house employees.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  SCHEDULE A CALL
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/itstaff/itstaff.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Direct Hiring</h1>
                <h4 className="heading4">FULL-TIME TECH TALENT</h4>
                <p>
                  Our dedicated direct placement recruiting services place
                  in-demand IT talent across North America
                </p>
                <p>
                  Proactive recruiting targets hidden talents and better
                  prepares customers for potentially tight labor pools.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  GROW YOUR IT TALENT
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/itstaff/hiring.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Contract Hiring</h1>
                <h4 className="heading4">SHORT-TERM HELP, LONG-TERM IMPACT</h4>

                <p>
                  Exceptional IT talent and scalable staffing solutions for
                  companies of all sizes. Our highly skilled IT consultants
                  extend your team’s capabilities without the overhead of
                  in-house employees.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  GET CONTRACTED IT STAFF
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/itstaff/contract.jpg" alt="img" />{" "}
              </div>
            </div>
          </div>
        </section>
        {/* End Technology we use Section  */}
      </main>
      {/*  Start Contact Section */}
      <div className="mobile-contact-section" id="">
        <ContactSection
          text={
            "Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you"
          }
        />
      </div>
      {/*  End Contact Section */}
    </>
  );
};

export default ITStaffing;
