import React from "react";
import "./Contact.css";

const ApplyCourseSmall = () => {
  return (
    <>
      <section className="apply-job">
        {/* <div class="">
          <form className="form">
            <input
              aria-label="Name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Full Name"
              aria-required="true"
            />

            <input
              aria-label="Email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter Email"
              aria-required="true"
            />

            <input
              aria-label="Mobile Number"
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Mobile Number"
              aria-required="true"
            />

            <select name="areaOfInterest" id="areaOfInterest">
              <option value="fswd">Full Stack Web Development</option>
              <option value="aiml">AI & ML</option>
              <option value="java">Java Training</option>
              <option value="ccpp">C/C++</option>
              <option value="python">Python Training</option>
            </select>


            <button type="submit" aria-label="Create Account">
              Apply
            </button>
          </form>
        </div> */}
        <div className="contact-card">
          <div className="center column">
            <h2 className="heading2">Free Career Counselling</h2>
            <p className="para2">
              Dont be shy let us know if you have any questions
            </p>
            <form className="contact-form">
              <input type="text" placeholder="Full Name" name="name" id="" required/>
              <input type="number" placeholder="Mobile Number" name="number" id="" required/>
              <input type="email" placeholder="Email" name="email" id="" required/>
              <select name="course" id="course">
                <option value="fswd">Full Stack Web Development</option>
                <option value="aiml">AI & ML</option>
                <option value="java">Java Training</option>
                <option value="ccpp">C/C++</option>
                <option value="python">Python Training</option>
              </select>
              <button type="submit" className={" cta"}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplyCourseSmall;
