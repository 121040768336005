/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import { Card } from "react-bootstrap";
import { FaReact } from "react-icons/fa";
import { FaJava } from "react-icons/fa";
import { SiSpring } from "react-icons/si";
import { SiSpringboot } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import { BsGit } from "react-icons/bs";
import { DiJavascript1 } from "react-icons/di";
import { ImHtmlFive } from "react-icons/im";
import { ImCss3 } from "react-icons/im";
import TechCard from "../../../../components/cards/TechCard";
import TestimonialCard from "../../../../components/cards/TestimonialCard";

const JavaFull = () => {
  return (
    <>
      <section id="course-top" className="java-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>Java Full Stack Web Development</h1>
            <h3>
              This Java Full Stack Developer course is designed to introduce you
              to front-end, middleware, and back-end web developement
              technologies. You will learn to build an end-to-end application,
              test and deploy code, store data using MongoDB, and much more.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src3={"assets/training/career.jpg"}
              title3={"Career Growth"}
              text3={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src1={"assets/training/payment.jpg"}
              title1={"Highest paid"}
              text1={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src2={"assets/training/productivity.jpg"}
              title2={"Productivity"}
              text2={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="top-mid">
        <div className="top-mid jcse">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>15 Dec, 2023</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>5 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <h1>Fees</h1>
            <p>&#8377;6999</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                Java full stack web development involves creating web
                applications using Java technologies for both the front end and
                back end. This typically includes frameworks such as Spring or
                Java EE for the back end, and technologies like HTML, CSS, and
                JavaScript for the front end. Java full stack developers possess
                expertise in integrating databases, managing server-side logic,
                and implementing user interfaces, enabling them to build secure,
                scalable, and high-performance web applications. They leverage
                various tools and libraries to ensure smooth communication
                between different layers of the application, delivering a
                comprehensive and efficient web development experience.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Comprehensive Curriculum
                </li>
                <li>
                  <VscPass />
                  Hands-on Projects
                </li>
                <li>
                  <VscPass />
                  Industry-Relevant Skills
                </li>
                <li>
                  <VscPass />
                  Experienced Instructors
                </li>
                <li>
                  <VscPass />
                  Latest Tools and Technologies
                </li>
                <li>
                  <VscPass />
                  Code Reviews and Feedback
                </li>
                <li>
                  <VscPass />
                  24*7 Support
                </li>
                <li>
                  <VscPass />
                  E-learning videos on Full Stack Development
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">
                Java Full Stack Developer Certification Advantage
              </h5>
              <p>
                Full Stack Java Developer program provides extensive hands-on
                training in front-end, middleware, and back-end Java technology
                skills along with phase-end and capstone projects.
              </p>
              <ul>
                <li>
                  Demonstrates proficiency in both front-end and back-end
                  development using Java technologies.
                </li>
                <li>
                Validates your expertise and knowledge in developing end-to-end Java-based web applications.
                </li>
                <li>
                Increases opportunities for career growth and progression within the field of Java full stack development.
                </li>
                <li>Sets you apart from other developers by showcasing your commitment to continuous learning and professional development.</li>
                <li>Enhances your appeal to potential employers seeking qualified professionals capable of handling the entire web application development process.</li>
                <li>Opens doors to diverse projects that require expertise in Java full stack development, contributing to a more varied and rewarding professional experience.</li>
                <li>Positions you for higher-paying roles due to your specialized skills and recognized proficiency in Java full stack development.</li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.jpg"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
              <h3 className="heading3">Backend</h3>
              <AccordionComp
                title={"Programming Fundamentals"}
                titleWidth={"100%"}
                jdhead={"Java"}
                jdli1={"Introduction to Core Java"}
                jdli2={"Installation and First Program"}
                jdli3={"Language Fundamentals"}
                jdli4={"Data Types in Java"}
                jdli5={"Operators in Java"}
                jdli6={"Statements in Java"}
                jdli7={"Loops in Java"}
                jdhead2={"Exception Handling and Threading "}
                jd2li1={"try – catch"}
                jd2li2={"throws"}
                jd2li3={"Threads"}
                jd2li4={"Synchronization & Thread Interaction"}
                jdhead3={"SQL"}
                jd3li1={"SQL Basics"}
                jd3li2={"Advanced SQL"}
                jd3li3={"Deep Dive into User Defined Functions"}
                jd3li4={"SQL Optimization and Performance"}
              />
              <AccordionComp
                title={"Object Oriented Programming"}
                titleWidth={"100%"}
                jdli1={"Classes and Objects"}
                jdli2={"Access modifiers"}
                jdli3={"Inheritance"}
                jdli4={"Polymorphism"}
                jdli5={"Encapsulation"}
                jdli6={"Abstraction"}
                jdli7={"Abstract class"}
                jdli8={"Abstract methods"}
                jdli9={"Interface"}
              />

              <AccordionComp
                title={"System Design"}
                titleWidth={"100%"}
                jdli1={"Introduction to System Design"}
                jdli2={"Design Principles"}
                jdli3={"Design Patterns"}
                jdli4={"High-Level Design (HLD)"}
                jdli5={"Low-Level Design (LLD)"}
                jdli6={"Scalability and Performance"}
                jdli7={"Security and Reliability"}
                jdli8={"Integration and Deployment"}
              />
              <AccordionComp
                title={"Spring Core and Spring Boot"}
                titleWidth={"100%"}
                jdli1={"Introduction to Spring Framework"}
                jdli2={"Spring Core Basics"}
                jdli3={"Aspect-Oriented Programming (AOP)"}
                jdli4={"Spring Boot Introduction"}
                jdli5={"Spring Boot Configuration"}
                jdli6={"Spring Boot Data Access"}
                jdli7={"Spring Boot Web Development"}
                jdli8={"Spring Boot Testing"}
                jdli9={"Spring Boot Security"}
                jdli10={"Spring Boot Actuator and Monitoring"}
                jdli11={"Deployment and Productionisation"}
                jdli12={"Microservices with Spring Boot"}
                jdli13={"Continuous Integration and Deployment"}
                jdli14={"Performance Optimization"}
              />

              <h3 className="heading3">Version Control</h3>
              <AccordionComp
                title={"Git & GitHub"}
                titleWidth={"100%"}
                jdhead={"Purpose of Git & GitHub"}
                jdli1={"Overview of version control"}
                jdli2={"Importance in software development"}
                jdli3={"Installing Git"}
                jdli4={"Initializing a Git repository"}
                jdli5={"Basic commands: add, commit, status"}
                jdli6={"Understanding branches"}
                jdli7={"Creating and switching branches"}
                jdli8={"Cloning remote repositories"}
                jdli9={"Pushing and pulling changes"}
                jdli10={"Basics of pull requests"}
                jdli11={"Creating a GitHub account"}
                jdli12={"Basics of GitHub repositories and issues"}
                jdli13={"Pushing changes to GitHub"}
              />
              <h3 className="heading3">Frontend</h3>
              <AccordionComp
                title={"HTML"}
                titleWidth={"100%"}
                jdhead={"Purpose of HTML"}
                jdli1={"Structuring web pages."}
                jdli2={"Navigating the internet."}
                jdli3={"Embedding images and videos."}
                jdli4={
                  "Improving client-side data storage and offline capabilities."
                }
                jdli5={"Game development."}
                jdli6={"Interacting with native APIs."}
              />
              <AccordionComp
                title={"CSS"}
                titleWidth={"100%"}
                jdhead={"Purpose of CSS"}
                jdli1={"Defining the styles for web pages."}
                jdli2={"Saves a lot of time."}
                jdli3={"Pages load faster."}
                jdli4={"Easier Website maintenance."}
                jdli5={"Multiple device compatibility."}
              />
              <AccordionComp
                title={"JavaScript"}
                titleWidth={"100%"}
                jdhead={"Purpose of JavaScript"}
                jdli1={
                  "It can be used both in the front-end and back-end of web development."
                }
                jdli2={
                  "Serves everyone from beginners to intermediate and even advanced developers."
                }
                jdli3={
                  "Should be able to interact with Client and get their updates done. Strong written and verbal communication skills in English"
                }
                jdli4={"Transformed web browsers into application platforms."}
                jdli5={
                  "Wide range of frameworks and libraries to help build complex applications."
                }
              />
              <AccordionComp
                title={"ReactJS"}
                titleWidth={"100%"}
                jdhead={"Purpose of ReactJS"}
                jdli1={"React is a Library of Javascript."}
                jdli2={"Large-scale applications."}
                jdli3={"High-performance applications."}
                jdli4={
                  "React excels at handling complex user interfaces with ease."
                }
                jdli5={
                  "React can be used to build web, mobile, and desktop applications."
                }
              />
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 5 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}
        {/* Start Technology we use Section  */}
        <h1 className="heading1">Skills Covered</h1>
        <ul className="skills-covered row flex-wrap">
          <li>Agile</li>
          <li>Git</li>
          <li>SQL</li>
          <li>Core Java</li>
          <li>Spring</li>
          <li>Spring Boot</li>
          <li>HTML and CSS</li>
          <li>JavaScript</li>
          <li>ReactJS</li>
          <li>JDBC</li>
        </ul>
        {/* End Technology we use Section  */}
        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap">
            
            <TechCard
              heading={"Java"}
              icon={< FaJava style={{ color: "#F14E32" }} />}
            />
            <TechCard
              heading={"Spring"}
              icon={< SiSpring style={{ color: "#3C873A" }} />}
            />
            <TechCard
              heading={"SpringBoot"}
              icon={<SiSpringboot style={{ color: "#3C873A" }} />}
            />
            <TechCard
              heading={"MySQL"}
              icon={<SiMysql style={{ color: "#00758F" }} />}
            />
            <TechCard
              heading={"Git & GitHub"}
              icon={<BsGit style={{ color: "#F14E32" }} />}
            />
            <TechCard
              heading={"HTML"}
              icon={<ImHtmlFive style={{ color: "#FF5733" }} />}
            />
            <TechCard
              heading={"CSS"}
              icon={<ImCss3 style={{ color: "#2965f1" }} />}
            />
            <TechCard heading={"JAVASCRIPT"} icon={<DiJavascript1 />} />
            <TechCard
              heading={"ReactJS"}
              icon={<FaReact style={{ color: "#61DBFB" }} />}
            />
          </div>
        </section>
        {/* End Technology we use Section  */}
        <section className="testimonial-section section ">
          <h2 className="heading2">Hear From Our Hiring Partners</h2>
          <div className="row">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </section>
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default JavaFull;
