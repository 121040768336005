import React from 'react'
import { NavLink } from "react-router-dom";
import './TopSection.css'

const TopSection = ({heading,text}) => {
  return (
    <>
    <section id="top-section">
        <div className="top-section container">
          <h1>{heading}</h1>
          <p>{text}</p>
          <NavLink className="cta" to={"/contact"}>
            Connect with us{" "}
          </NavLink>
        </div>
      </section>
    </>
  )
}

export default TopSection