/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import { Card } from "react-bootstrap";
import TestimonialCard from "../../../../components/cards/TestimonialCard";

const DevOps = () => {
  return (
    <>
      <section id="course-top" className="mern-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>DevOps</h1>
            <h3>
              DevOps Fundamentals covers essential principles and tools for
              seamless collaboration between development and operations.
              Participants learn key concepts such as continuous integration,
              deployment, infrastructure as code, and security.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src1={"assets/training/career.jpg"}
              title1={"Career Growth"}
              text1={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src2={"assets/training/payment.jpg"}
              title2={"Highest paid"}
              text2={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src3={"assets/training/productivity.jpg"}
              title3={"Productivity"}
              text3={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="top-mid">
        <div className="top-mid jcse">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>15 Dec, 2023</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>5 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <h1>Fees</h1>
            <p>&#8377;6999</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                The DevOps Fundamentals course provides a comprehensive
                introduction to the principles, practices, and tools that drive
                the DevOps methodology. Designed for IT professionals, software
                developers, and system administrators, this course equips
                participants with the knowledge and skills essential for
                successful DevOps implementation.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Comprehensive Curriculum
                </li>
                <li>
                  <VscPass />
                  Hands-on Experience
                </li>
                <li>
                  <VscPass />
                  Expert Instruction
                </li>
                <li>
                  <VscPass />
                  Real-world Case Studies
                </li>
                <li>
                  <VscPass />
                  Interactive Learning
                </li>
                <li>
                  <VscPass />
                  Tool Proficiency
                </li>
                <li>
                  <VscPass />
                  Cultivation of DevOps Culture
                </li>
                <li>
                  <VscPass />
                  Certification Opportunities
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">DevOps Certification Advantage</h5>
              <p>
                Earning a DevOps certification can offer several advantages for
                professionals in the IT and software development industry:
              </p>
              <ul>
                <li>
                  <b> Validation of Expertise:</b>DevOps certifications serve as
                  a formal validation of an individual's proficiency in key
                  DevOps concepts, practices, and tools, providing tangible
                  evidence of expertise.
                </li>
                <li>
                  <b>Career Advancement:</b>Certifications enhance your
                  marketability and can open up new career opportunities. Many
                  employers actively seek professionals with recognized DevOps
                  certifications.
                </li>
                <li>
                  <b>Increased Employability:</b>DevOps is a sought-after skill
                  set in the industry, and having a certification can make you
                  stand out among job applicants, increasing your chances of
                  being hired.
                </li>
                <li>
                  <b>Standardized Knowledge Base:</b>Certifications often follow
                  industry standards, ensuring that professionals have a
                  standardized knowledge base that aligns with best practices in
                  the DevOps field.
                </li>
                <li>
                  <b>Networking Opportunities:</b>Certification programs often
                  provide opportunities to connect with other professionals in
                  the field, fostering networking opportunities that can be
                  valuable for career growth.
                </li>
                <li>
                  <b>Competitive Advantage:</b>In a competitive job market,
                  having a DevOps certification can give you a competitive edge
                  over other candidates, showcasing your specialized skills and
                  knowledge.
                </li>
                <li>
                  <b>Adaptability to Industry Changes:</b>DevOps certifications
                  often cover a broad range of topics, preparing professionals
                  to adapt to changes in technology and methodologies within the
                  rapidly evolving DevOps landscape.
                </li>
                <li>
                  <b>Global Recognition:</b>Many DevOps certifications are
                  globally recognized, making them valuable for professionals
                  working in various regions and industries.
                </li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.jpg"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
              <AccordionComp
                title={"DevOps Certification Training"}
                titleWidth={"100%"}
                jdli1={"Course Introduction"}
                jdli2={"Introduction to DevOps"}
                jdli3={"Version Control Systems"}
                jdli4={
                  "Continuous Integration, Continuous Deployment, and Build Tools"
                }
                jdli5={"Software and Automation Testing Frameworks"}
                jdli6={"Configuration Management Tools"}
                jdli7={"Containerization with Docker"}
                jdli8={"Continuous Monitoring"}
                jdli9={"Need of Cloud in DevOps"}
                jdli10={"Practice Projects"}
              />
              <AccordionComp
                title={"Git & GitHub"}
                titleWidth={"100%"}
                jdhead={"Purpose of Git & GitHub"}
                jdli1={"Overview of version control"}
                jdli2={"Importance in software development"}
                jdli3={"Installing Git"}
                jdli4={"Initializing a Git repository"}
                jdli5={"Basic commands: add, commit, status"}
                jdli6={"Understanding branches"}
                jdli7={"Creating and switching branches"}
                jdli8={"Cloning remote repositories"}
                jdli9={"Pushing and pulling changes"}
                jdli10={"Basics of pull requests"}
                jdli11={"Creating a GitHub account"}
                jdli12={"Basics of GitHub repositories and issues"}
                jdli13={"Pushing changes to GitHub"}
              />
              <AccordionComp
                title={"CI/CD Pipeline with Jenkins"}
                titleWidth={"100%"}
                jdli1={"Course Introduction"}
                jdli2={
                  "Introduction to CI/CD"
                }
                jdli3={"Getting Started with Jenkins"}
                jdli4={"Build Jobs and Configurations"}
                jdli5={"Configuring Build Pipelines"}
                jdli6={"Automated Testing In Jenkins"}
                jdli7={"Code Quality Improvement Using Jenkins"}
                jdli8={"Automated Deployment and Continuous Delivery"}
                jdli9={"Distributed System in Jenkins"}
              />
              <AccordionComp
                title={"Docker Certified Associate"}
                titleWidth={"100%"}
                jdli1={"Course Introduction"}
                jdli2={"Introduction to Docker"}
                jdli3={"Image Creation, Management, and Registry"}
                jdli4={"Orchestration"}
                jdli5={"Networking"}
                jdli6={"Installation and Configuration of Docker Enterprise"}
                jdli7={"Security"}
              />
              <AccordionComp
                title={"Certified Kubernetes Administrator"}
                titleWidth={"100%"}
                jdli1={"Core Concepts"}
                jdli2={"Scheduling Pods"}
                jdli3={"Logging and Monitoring"}
                jdli4={"Application Lifecycle Management"}
                jdli5={"Cluster Maintenance"}
                jdli6={"Security and Authentication"}
                jdli7={"Storage and Volumes"}
                jdli8={"DNS Networking, CoreDNS, and CNI"}
                jdli9={"Troubleshooting: Application Failures, Control Panel Failures, and Network Failures"}

              />
              <AccordionComp
                title={"DevOps on AWS"}
                titleWidth={"100%"}
                jdli1={"Getting Started with DevOps on AWS Cloud"}
                jdli2={"Spinning Up an IDE in AWS Cloud with Cloud9"}
                jdli3={"Building Applications with AWS CodeBuild"}
                jdli4={"Deploying Applications with AWS CodeDeploy"}
                jdli5={"Automating Deployment with AWS CodePipeline"}
                jdli6={"DevOps with AWS CodeStar"}
                
              />
              <AccordionComp
                title={"AWS Developer Associate"}
                titleWidth={"100%"}
                jdli1={"AWS Overview"}
                jdli2={"AWS Services"}
              
              />
             
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 5 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}

        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap center">
            <img src="assets/training/devops/apache-ant.webp" alt="" />
            <img src="assets/training/devops/cmaven.avif" alt="" />
            <img src="assets/training/devops/ansible.webp" alt="" />
            <img src="assets/training/devops/chef.avif" alt="" />
            <img src="assets/training/devops/puppets.webp" alt="" />
            <img src="assets/training/devops/saltstack.webp" alt="" />
            <img src="assets/training/devops/Apache-camel.avif" alt="" />
            <img src="assets/training/devops/jenkins.webp" alt="" />
            <img src="assets/training/devops/teamcity.webp" alt="" />
            <img src="assets/training/devops/cgen.webp" alt="" />
            <img src="assets/training/devops/cnagios.webp" alt="" />
            <img src="assets/training/devops/junit.webp" alt="" />
            <img src="assets/training/devops/cvs.webp" alt="" />
            <img src="assets/training/devops/git.avif" alt="" />
            <img src="assets/training/devops/csubversion.webp" alt="" />
          </div>
        </section>
        {/* End Technology we use Section  */}
        <section className="testimonial-section section ">
          <h2 className="heading2">Hear From Our Hiring Partners</h2>
          <div className="row">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </section>
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default DevOps;
