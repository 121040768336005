import React from "react";
import "./WebDevelopment.css";
import { NavLink } from "react-router-dom";

import WebDevelopmentSlider from "../../../components/cardsliders/WebDevelopmentSlider";
import TechCard from "../../../components/cards/TechCard";
import HoverComponentView from "../../../components/hoverComponentView/HoverComponentView";
import OfferingSlider from "../../../components/cardsliders/OfferingSlider";
import TitleTextCard from "../../../components/cards/TitleTextCard";
import ContactSection from "../../../components/contact/ContactSection";
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { SiCivicrm } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import { BiLogoJavascript } from "react-icons/bi";
import { ImHtmlFive } from "react-icons/im";
import { ImCss3 } from "react-icons/im";

const WebDevelopment = () => {
  return (
    <>
      <section id="webdevelopment-top">
        <div className="webdevelopment-top container">
          <div className="webdevelopment-top-left">
            <h1>Web Development</h1>
            <p>
              Our comprehensive web development offerings cover everything from
              cutting-edge website design to the development of robust back-end
              systems. We take pride in creating visually stunning,
              user-friendly, and responsive websites that engage your audience
              effectively and help achieve your online goals.
            </p>
            <NavLink className="cta" to={"/contact"}>
            Get a Quote
          </NavLink>
          </div>
          <div className="webdevelopment-top-right">
            <WebDevelopmentSlider />
          </div>
        </div>
      </section>

      <main className="container">
        {/* Start Custom Website Section  */}

        <h3 className="heading3">
          Custom Website Design & Development Services
        </h3>
        <section className=" webdevelopment-custom-container section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading5">Crafting Unique Web Solutions:</h5>
              <p>
                we believe that your online presence should be as unique as your
                business. Our team of experts works closely with you to create
                custom web designs that not only look stunning but also resonate
                with your brand's identity and values.
              </p>
              <h5 className="heading5">Responsive Across All Devices:</h5>
              <p>
                In a world where users access websites on various devices, we
                prioritize responsiveness. Our websites are optimized to deliver
                a consistent and enjoyable user experience across desktops,
                tablets, and smartphones.
              </p>
              <h5 className="heading5">Optimized for Search:</h5>
              <p>
              We understand the importance of visibility. That's why we integrate SEO best practices into our web development process, helping your website rank higher in search results and attract organic traffic.
              </p>
            </div>

            <figure className="content-right">
              <img src="assets/service/web/web.jpg" alt="Web Development Image" />
            </figure>
          </div>
        </section>
        {/* End Custom Website Section  */}

        {/* Start Technology we use Section  */}
        <h1 className="heading1">Technologies we use</h1>
        <section className="technologies-weuse-container section row flex-wrap center">
          
          <TechCard
            heading={"JAVASCRIPT"}
            icon={<BiLogoJavascript/>}
          />
          <TechCard
            heading={"ReactJS"}
            icon={<FaReact/>}
          />
          <TechCard
            heading={"NodeJS"}
            icon={<FaNodeJs/>}
          />
          <TechCard
            heading={"ExpressJS"}
            icon={<SiExpress/>}
          />
          <TechCard
            heading={"MongoDB"}
            icon={<SiMongodb/>}
          />
          <TechCard
            heading={"MySQL"}
            icon={<GrMysql/>}
          />
          <TechCard
            heading={"HTML"}
            icon={<ImHtmlFive/>}

          />
          <TechCard
            heading={"CSS"}
            icon={<ImCss3/>}
          />
          <TechCard
            heading={"CRM"}
            icon={<SiCivicrm/>}
          />
        </section>
        {/* End Technology we use Section  */}

        {/* Start Industries we serve container  */}
        <h1 className="heading1">Industries we serve</h1>
        <section className="industries-weserve-container section">
          <HoverComponentView />
        </section>
        {/* End Industries we serve container  */}

        {/* Start Our Offerings container  */}
        <h1 className="heading1">Our offerings for web design & development</h1>
        <section className="our-offereings-container section">
          <OfferingSlider />
        </section>
        {/* End Our Offerings container  */}

        {/* Start Why choose us container  */}
        <h1 className="heading1">Why choose us</h1>
        <section className="why-choose-us-container section ">
          <TitleTextCard heading={"Maintenance"} text={"Hello Text"} />
          <TitleTextCard
            heading={"Custom web development"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Use of latest Framework & Technology"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Transparent & Flexible Process"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Strategic & Asured Quality"}
            text={"Hello Text"}
          />
        </section>
        {/* End Why choose us container  */}

        {/* Start Pricing container  */}
        <h1 className="heading1">Pricing</h1>
        <section className="web-pricing-container section ">
          <div className="price1">
            <h2>Basic- INR (Rs. 4200/-)</h2>
            <ul>
              <li>Landing Page + 03 pages</li>
              <li>*Hosting + Domain registration (Free)</li>
              <li>Static Web Design & Development</li>
              <li>Build with CMS</li>
              <li>Fully Mobile Responsive</li>
              <li>SSL Integrations (Secure Websites’)</li>
              <li>SEO Friendly</li>
              <li>01 Contact Form (Without DB Integration)</li>
              <li>02 Email Accounts</li>
              <li>Delivery Time: 05 Working Days</li>
              <li>01 Year Free Support</li>
            </ul>

          </div>
          <div className="price2">
            <h2>Standard- INR (Rs. 10500/-)</h2>
            <ul>
              <li>Landing Page + 09 Pages</li>
              <li>*Hosting + Domain registration (Free)</li>
              <li>Dynamic Web Design & Development</li>
              <li>Build with CMS, MySQL Database</li>
              <li>Fully Mobile Responsive</li>
              <li>Social Media Integration</li>
              <li>SSL Integrations (Secure Websites’)</li>
              <li>SEO Friendly</li>
              <li>02 Contact Form (**With Database Integration)</li>
              <li>~Google Map integration</li>
              <li>~Click to call action integration</li>
              <li>03 Email Accounts</li>
              <li>Delivery Time: 11 Working Days</li>
              <li>01 Year Free Support</li>
            </ul>
          </div>
          <div className="price3">
            <h2>Professional- INR (Rs.19000/-)</h2>
            <ul>
              <li>Landing Page + 14 Pages</li>
              <li>*Hosting + Domain registration (Free)</li>
              <li>Dynamic Web Design & Development</li>
              <li>Build with CMS, HTLM, CSS3, PHP, React JS, MySQL Database</li>
              <li>eCommerce Web site build with OpenCart / WooCommerce / Shopify / PrestaShop</li>
              <li>Fully Mobile Responsive</li>
              <li>Social Media Integration</li>
              <li>SSL Integrations (Secure Websites’)</li>
              <li>SEO Friendly</li>
              <li>~03 Contact Form (**With Database Integration)</li>
              <li>~Google Map integration</li>
              <li>~Click to call action integration</li>
              <li>~Chat Bolt (Live Chat Integration)</li>
              <li>05 Email Accounts</li>
              <li>News Letter Subscription</li>
              <li>Delivery Time: 15 Working Days</li>
              <li>01 Year Free Support</li>
            </ul>
          </div>
          <div className="price4">
            <h2>Enterprise - INR (Rs. 39000/-)</h2>
            <ul>
              <li>Landing Page + 19 Pages</li>
              <li>E-com & Custom Web Development</li>
              <li>*Hosting + Domain registration (Free)</li>
              <li>Dynamic Web Design & Development</li>
              <li>eCommerce Web site build with OpenCart / WooCommerce / Shopify / PrestaShop</li>
              <li>Build with React JS, Bootstrap, Java Script, Node JS, PHP, Angular, Mongo Database</li>
              <li>Fully Mobile Responsive</li>
              <li>Social Media Integration</li>
              <li>SSL Integrations (Secure Websites’)</li>
              <li>SEO Friendly</li>
              <li>~ 02 Contact Form (With Database Integration if required)</li>
              <li>~Google Map integration</li>
              <li>~Click to call action integration</li>
              <li>`Chat Bolt (Live Chat Integration)</li>
              <li>09 Email Accounts</li>
              <li>News Letter Subscription</li>
              <li>Delivery Time: 25 Working Days</li>
              <li>01 Year Free Support</li>
            </ul>
          </div>
        </section>
        {/* End Pricing container  */}

          </main>

        <ContactSection
          text={
            "With our expertise in Healthcare, FinTech, e-commerce, and other business areas, we can take care of your project at any stage. Whether it is a process from scratch or already embedded into the existing process - a web, mobile, or any software development solution will be brought to perfection. For this purpose, we assemble a team of strong professionals for each project."
          }
        />  
    </>
  );
};

export default WebDevelopment;
