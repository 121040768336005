import React from "react";
import "./Home.css";
import SideNav from "../../components/Navbar/SideNav";
import { FaLaptopCode } from "react-icons/fa";
import ContactSection from "../../components/contact/ContactSection";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <>
      <SideNav />
      <main>
        <section id="home-top">
          <div className="home-top container">
            <div className="home-top-left">
              <h1>Empowering a livable future</h1>
              <h3>
                In present situation of world, ICOESS is commited to provide
                adative services & solutions to our cusomers. ICOESS helps
                businesses thrive in a world defined by disruption and fueled by
                transformative technology.
              </h3>
              <NavLink className={'cta'} to={'technicalTraining'}>Our Technical Training</NavLink>
            </div>
            <div className="home-top-right">
              <video
                src="assets/videos/Intro.mp4"
                controls
                muted
                autoPlay
              ></video>
            </div>
          </div>
        </section>

        {/* Start Front Intro Section  */}

        <section className="article front-intro ">
          <div className="content">
            <h1 className="headline">Build your Web & Mobile Apps</h1>
            <p>
              Attractive Websites are doors of your Business. Our Team of
              Experts provides the custom build, Sophisticated & Full Featured
              Websites. Our Full Stack Developer team builds Robust Web
              Applications.
            </p>
            <p>
              Mobile app development is rapidly growing.Today, mobile devices—and the
              mobile applications that unlock their value—are the most popular
              way for people and businesses to connect to the internet.
            </p>
          </div>

          <figure className="image-wrap">
            <img src="assets/tech.jpg" alt="Shiv Shakti Gurukulam" />
          </figure>
        </section>
        {/* End Front Intro Section  */}

        {/* Start Home Services Section  */}
        <section id="home-services" className="main section-wrap">
          <div className="home-services ">
            <div className="home-services-header">
              <h1 className="section-title">
                Our <span>Services</span>
              </h1>
            </div>
            <div className="all-home-services">
              <div className="card2-item article">
                <div className="card2-info">
                  <h1>Corporate Training</h1>

                  <p>
                    Corporate Technical Training by our Company is
                    a tailored learning program designed to empower
                    organizations with cutting-edge technical skills and
                    knowledge. Our comprehensive training solutions are crafted
                    to enhance the proficiency of your workforce, fostering
                    innovation, efficiency, and competitiveness. With
                    experienced instructors and customizable training modules,
                    we ensure that your team gains the expertise needed to excel
                    in today's rapidly evolving technological landscape. Elevate
                    your company's capabilities and stay ahead of the curve with
                    our Corporate Technical Training.
                  </p>
                  <NavLink to={"/technicalTraining"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/training.jpg" alt="img" />{" "}
                </div>
              </div>
              <div className="card2-item article">
                <div className="card2-info">
                  <h1>Education Support</h1>

                  <p>
                    We offers
                    invaluable assistance to students, educators, and
                    institutions pursuing excellence in STEM education. Our
                    comprehensive support services encompass curriculum
                    development, teacher training, and technology integration,
                    enabling seamless and engaging learning experiences. With a
                    focus on Science, Computing, and Engineering (SCE), we
                    empower educators to inspire the next generation of
                    innovators and equip students with the skills they need for
                    a bright future. Elevate your educational initiatives with
                    our SCE Education Support.
                  </p>
                  <NavLink to={"/EducationSupport"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/education.jpg" alt="img" />{" "}
                </div>
              </div>
              <div className="card2-item article">
                <div className="card2-info">
                  <h1>Web Development</h1>
                  <p>
                    We offer top-notch Web Development
                    services to transform your digital presence. Our expert team
                    crafts responsive, visually stunning, and highly functional
                    websites tailored to your specific needs. From e-commerce
                    platforms to dynamic web applications, we provide end-to-end
                    solutions that ensure a seamless user experience and drive
                    your online success. Trust us for innovative
                    and efficient Web Development that sets you apart in the
                    digital landscape.
                  </p>
                  <NavLink to={"/webd"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/web.jpg" alt="img" />{" "}
                </div>
              </div>
              <div className="card2-item article">
                <div className="card2-info">
                  <h1>Mobile Apps</h1>

                  <p>
                    Our Company specializes in Mobile App
                    Development, creating dynamic and user-friendly applications
                    for iOS and Android platforms. Our team of skilled
                    developers and designers harness cutting-edge technology to
                    bring your app ideas to life. Whether it's a business app,
                    game, or utility tool, we tailor each solution to your
                    unique requirements, ensuring a seamless and engaging user
                    experience. Elevate your mobile presence with our Mobile App Development services and stay ahead in
                    the rapidly evolving digital landscape.
                  </p>
                  <NavLink to={"/mobiled"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/app.jpg" alt="img" />{" "}
                </div>
              </div>

              <div className="card2-item article">
                <div className="card2-info">
                  <h1>S/w Dev & OutSourcing</h1>

                  <p>
                    We offer top-notch Software
                    Development & Outsourcing services, providing customized
                    solutions to meet your software needs. Our expert team
                    combines technical prowess with a deep understanding of your
                    business objectives to deliver cost-effective and efficient
                    software solutions. Whether you need application
                    development, maintenance, or dedicated offshore development
                    teams, we are your trusted partner. Elevate your software
                    capabilities and streamline your operations with our Software Development & Outsourcing services.
                  </p>
                  <NavLink to={"/SwDevOutsourcing"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/outsourcing.jpg" alt="img" />{" "}
                </div>
              </div>
              <div className="card2-item article">
                <div className="card2-info">
                  <h1>IT Staffing & Smart Hire</h1>

                  <p>
                    Our Company specializes in IT Staffing & Smart
                    Hire services, helping businesses find the right talent for
                    their technological needs. Our smart hiring solutions
                    leverage data-driven approaches to identify the most
                    suitable candidates, saving you time and resources in the
                    recruitment process. Whether you require short-term contract
                    staff or long-term placements, we connect you with skilled
                    professionals who align with your company's goals and
                    culture. Elevate your IT team with our IT
                    Staffing & Smart Hire services and ensure a seamless fit for
                    your organization's success.
                  </p>
                  <NavLink to={"/itstaffing"} className="cta">
                    Know More...
                  </NavLink>
                </div>
                <div className="card2-img">
                  <img src="assets/service/web.jpg" alt="img" />{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Home Services Section */}

        {/*  Start Insights Section */}

        <section id="insights" className="insights-section container">
          <div className="flex insights-container container">
            <div className="insight-info">
              <h2 className="heading2">Insights</h2>
              <p className="paragraph">
                Our Thinking of Ideas, Technologies & Trends that create impact
              </p>
            </div>
            <div className="insight-imgs row">
              <div className="grid_item center">
                <div class="card">
                  <img src="assets/background.jpg" alt="Project 1" />
                  <a href="/">
                    <div class="card_content">
                      <h3>Read More</h3>
                    </div>
                  </a>
                </div>
              </div>
              <div className="grid_item center">
                <div class="card">
                  <img src="assets/background.jpg" alt="Project 1" />
                  <a href="/">
                    <div class="card_content">
                      <h3>Read More</h3>
                    </div>
                  </a>
                </div>
              </div>
              <div className="grid_item center">
                <div class="card">
                  <img src="assets/background.jpg" alt="Project 1" />
                  <a href="/">
                    <div class="card_content">
                      <h3>Read More</h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End Insights Section */}

        {/*  Start Service Areas Section */}

        <section id="service-areas" className="service-areas container">
          <h1 className="section-title">
            Our Service <span>Areas</span>
          </h1>
          <div className="column">
            <div className="row jcsb flex-wrap">
              <div className="course-item item1 wh-150 ">
                <p>Health Care</p>
              </div>
              <div className="course-item item2 wh-150 ">
                <p>Ecommerce Solutions</p>
              </div>
              <div className="course-item item3 wh-150 ">
                <p>Training and Education</p>
              </div>
              
              <div className="course-item item4 wh-150 ">
                <p>Real Estate</p>
              </div>
              </div>
              <div className="row jcsb flex-wrap">
            
              <div className="course-item item5 wh-150 ">
                <p>IT Project Management</p>
              </div>
              <div className="course-item item6 wh-150 ">
                <p>AI and ML Services</p>
              </div>
              
              <div className="course-item item7 wh-150 ">
                <p>IT Consulting</p>
              </div>
              <div className="course-item item8 wh-150 ">
                <p>Custom Softwares</p>
              </div>
            </div>
          </div>
        </section>
        {/*  End Service Areas Section */}

        {/*  Start inside icoess Section */}

        <section id="inside-icoess" className=" inside-icoess container">
          <div className="flex insights-container">
            <div className="insight-info">
              <h2 className="heading2">Inside ICOESS</h2>
            </div>
            <div className="insight-imgs row">
              <div className="work">
                <div className="grid_item">
                  <div className="inside-icoess-card">
                    <div className="image_container">
                      <img src="assets/background.jpg" alt="web development" />
                    </div>
                    <div className="inside-icoess-card_content">
                      <h3>UI UX Design</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ratione quibusdam ex quaerat quasi nostrum
                        tempore.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid_item">
                  <div className="inside-icoess-card">
                    <div className="image_container">
                      <img src="assets/background.jpg" alt="web development" />
                    </div>
                    <div className="inside-icoess-card_content">
                      <h3>UI UX Design</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ratione quibusdam ex quaerat quasi nostrum
                        tempore.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid_item">
                  <div className="inside-icoess-card">
                    <div className="image_container">
                      <img src="assets/background.jpg" alt="web development" />
                    </div>
                    <div className="inside-icoess-card_content">
                      <h3>UI UX Design</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ratione quibusdam ex quaerat quasi nostrum
                        tempore.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  End inside icoess Section */}

        {/* Start About Us Section  */}

        {/* End About Us Section  */}
        {/*  Start Contact Section */}
        <div className="" id="home-contact-section">
          <ContactSection
            text={
              "Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you"
            }
          />
        </div>
        {/*  End Contact Section */}
      </main>
    </>
  );
};

export default Home;
